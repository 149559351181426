@import '../colors';

.profilePicture {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	width: 38px;
	height: 38px;
	border-radius: 38px;
	border: 2px solid $purple;
	background-color: $purple;
	color: white;
	&::before {
		position: relative;
		top: -2px;
	}
}

@media screen and (max-width: 991px) {
	.profilePicture {
		position: absolute;
		top: 13px;
	}
}
