@import '../colors';

.header {
	position: relative;
	align-items: center;
	&Container {
		border-bottom: 1px solid $grayDark;
	}
	h1 {
		font-size: 1rem;
		margin-bottom: 0;
	}
}

.title {
	position: relative;
	left: 60px;
}

.nav {
	display: flex;
	gap: 1rem;
}

.newChatButton {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	padding: 0;
	cursor: pointer;
	font-size: 1.2rem;
	font-weight: 600;
	text-transform: uppercase;
	span {
		display: block;
		width: 100%;
		height: 100%;
	}
}

@media screen and (min-width: 991px) {
	.navbar {
		position: relative;
		width: max-content !important;
		flex-grow: 0 !important;
	}
}
