@import './colors';

html,
body {
	width: 100vw !important;
	margin: 0;
	padding: 0;
}

* {
	box-shadow: none !important;
	&:active,
	&:focus,
	&:hover {
		box-shadow: none !important;
	}
}

.alert {
	&-purple {
		background-color: $purple;
		border: 1px solid darken($purple, 5%);
		color: #fff;
	}
}

.btn {
	&:active,
	&:focus,
	&:hover {
		outline: none !important;
		box-shadow: none !important;
	}
	&-purple {
		background-color: $purple;
		color: #fff;
		&:active,
		&:focus,
		&:hover {
			background-color: darken($purple, 5%) !important;
			color: white !important;
		}
	}
	&-outline-purple {
		background-color: transparent;
		border: 1px solid $purple;
		color: $purple;
	}
	&-transparent {
		background-color: transparent;
	}

	&-xl {
		padding: 1.5rem 2rem;
		font-size: 1.5rem;
	}
}

.bg {
	&-gray {
		background-color: $gray;
	}
	&-transparent {
		background-color: transparent;
	}
}

.navbar {
	&-toggler {
		border: none;
		padding: 0;
		&:active {
			border: none;
			outline: none;
			box-shadow: none;
		}
	}
}

.rounded {
	border-radius: 50% !important;
}
