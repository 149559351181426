$transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

@keyframes bubble {
	0% {
		transform: translateY(5px);
	}
	100% {
		transform: translateY(0px);
	}
}
