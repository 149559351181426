@import "../mixins";

.chat {
	padding: 60px 0 70px;
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
}

.message {
	position: relative;
	margin: 0 !important;
	width: max-content;
	max-width: 80%;
	padding: 0.5rem 1rem !important;
	@include bubble;
	&Bot {
		text-align: left;
		align-self: start !important;
	}
	&User {
		text-align: right;
		align-self: end !important;
	}
	& > *:last-child {
		margin-bottom: 0;
	}
}

@media screen and (min-width: 992px) {
	.chat {
		padding: 70px 0;
	}
}
