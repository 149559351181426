@import "../colors";
@import "../mixins";

.footer {
	gap: 0.5rem;
	&Container {
		border-top: 1px solid $grayDark;
	}
}

.buttonNext {
	display: flex !important;
	justify-content: center;
	align-items: center;
	height: 30px;
	width: 30px;
	box-sizing: border-box;
	padding: 0 !important;
}
.input {
	border: none !important;
	min-height: 50px;
	height: auto;
	resize: none;
	&Container {
		width: calc(100% - 50px) !important;
		border: 1px solid $grayDark;
		border-radius: 7px;
		&:focus-within {
			border: 1px solid $purple !important;
		}
	}
}
.buttonSend {
	background-color: white !important;
	border: none !important;
	color: $purple !important;
	@include transition;
}
